import { WARRANTY_REGISTRATION_OMS_URL } from "../components/Shared/constant";

const OmsWarrantyAPI = async (payload) => {
    const responce = window.axios.post(WARRANTY_REGISTRATION_OMS_URL, payload, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-EM-KEY': process.env.REACT_APP_X_EM_KEY_URL,
    }}).then(function (result) {
        return {
          ...result.data,
          status:200
        };
    }).catch(function (result) {
        return {
          ...result?.response?.data,
          status:result?.response?.status
        }
    });
    return responce;
};
  
export default OmsWarrantyAPI;