/* eslint-disable */
import React, { useState, createContext, useEffect } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import "./assets/css/style.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import { REGESTER_PRODUCT_URL, WELCOME_REGESTER_PRODUCT_URL, CONGRATULATIONS_URL, SPIN_GIFT_URL } from './components/Shared/constant';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useWebSocket from './Api/UseWebsocket';
import { useDispatch, useSelector } from 'react-redux';
import Welcome from './components/Registerproduct';
import Registerproduct from './components/Registerproduct/Registerproduct';
import Congrats from './components/Registerproduct/Congrats';
import { SearchPincodeList, ErrorMessageFromRedux, clearRedux } from './redux/actions/adminActions';
// import LuckySpin from './components/Registerproduct/LuckySpin';
export const WebSocketContext = createContext();
window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

function App() {
  const Dispatch = useDispatch();
  const [ GetWEBSocket, setGetWEBSocket ] = useState("");
  const [ GetCurrentPath, setGetCurrentPath ] = useState("");
  const { cacheClear__ } = useSelector((state) => state.adminReducers);

  // websocket connection
  useWebSocket(`${process.env.REACT_APP_SERVICE_URL}/guest/`, setGetWEBSocket, Dispatch);

  useEffect(() => {
    return () => {
        Dispatch(SearchPincodeList({}));
        Dispatch(ErrorMessageFromRedux({
            API: "",
            Error: "",
        }));
    };
  }, [ GetCurrentPath ]);

  useEffect(() => {
    if(cacheClear__ === undefined) {
      Dispatch(clearRedux());
    };
  }, [ cacheClear__ ]);

  return (<WebSocketContext.Provider value={{websocket:GetWEBSocket}}>
      <div className="wrapper">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Welcome setGetCurrentPath={setGetCurrentPath}/>} >
                <Route path={WELCOME_REGESTER_PRODUCT_URL} element={<Welcome setGetCurrentPath={setGetCurrentPath} />} />
            </Route>
            <Route path={REGESTER_PRODUCT_URL} element={<Registerproduct setGetCurrentPath={setGetCurrentPath}/>} />
            <Route path={CONGRATULATIONS_URL} element={<Congrats setGetCurrentPath={setGetCurrentPath}/>} />
            {/* <Route path={SPIN_GIFT_URL} element={<LuckySpin setGetCurrentPath={setGetCurrentPath}/>} /> */}
            <Route path="*" element={<Navigate replace to={WELCOME_REGESTER_PRODUCT_URL}/>} />
          </Routes>
        </BrowserRouter>
        <ToastContainer />
      </div>
  </WebSocketContext.Provider>);
}

export default App;
